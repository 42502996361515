'use client';

import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { Button } from '../src/ui/Button';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    Sentry.captureUnderscoreErrorException({ err: error }).then();
  }, [error]);

  return (
    <main className="flex min-h-full flex-col justify-center h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-indigo-600">500</p>
        <h1 className="font-poppins mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Server Error
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          Sorry, something went wrong on our side.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button
            className="w-2/3"
            onClick={() => {
              // Attempt to recover by trying to re-render the segment
              reset();
            }}
          >
            Try again
          </Button>
        </div>
      </div>
    </main>
  );
}
